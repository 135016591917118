import React from 'react'
import { useUiKit } from '../hooks/uikit'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export default function Subscribe() {
  const { t, i18n } = useTranslation()
  const [email, setEmail] = React.useState('')
  const UIkit = useUiKit()

  const onSubmitBtnClick = (e: any) => {
    e.preventDefault()

    const formData = {
      email: email,
      type: 'business',
    }
    fetch('https://jasnitacms.jasnita.co.id/api/subscribe-email', {
      method: 'POST',
      // referrerPolicy: 'origin-when-cross-origin',
      headers: {
        Accept: '*/*',
        // 'Accept-Encoding': 'gzip, deflate, br',
        'Content-Type': 'application/json',
        // 'Cache-Control': 'no-cache',
        // Connection: 'keep-alive',
        // Origin: 'localhost:8000',
        // Host: 'https://jasnitacms.jasnita.co.id',
        // 'Pragma': 'no-cache',
        // 'Sec-Fetch-Dest': 'empty',
        // 'Sec-Fetch-Mode': 'cors',
        // 'Sec-Fetch-Site': 'cross-site',
        'User-Agent':
          'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.106 Safari/537.36',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) =>
        UIkit.notification(
          `<span uk-icon='icon: ${data.status === 'success' ? 'check' : 'warning'}'></span> ` + data.message,
          {
            status: data.status === 'success' ? 'success' : 'warning',
            pos: 'bottom-left',
          }
        )
      )
  }

  return (
    <section className="uk-section uk-flex uk-flex-middle tm-section-gradient">
      <div className="uk-width-1-1">
        <div className="uk-container">
          <div className="uk-width-xlarge uk-margin-auto uk-text-center">
            <h5>{t('Subscribe to receive our updates')}.</h5>
            <form className="uk-margin-top" onSubmit={onSubmitBtnClick} uk-grid="">
              <div className="uk-width-expand@s">
                <input
                  className="uk-input "
                  type="email"
                  placeholder={t('Enter your email')}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="uk-width-1-4@s">
                <button className="uk-button uk-button-primary" onClick={onSubmitBtnClick}>
                  {t('Subscribe')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}
