import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Subscribe from '../../components/subscribe'

// Load Page Contents
import contentEn from '../../../content/pages/investor/home.en.yaml'
import contentId from '../../../content/pages/investor/home.id.yaml'
import SEO from '../../components/seo'

export const query = graphql`
  query InvestorQuery($language: String!) {
    locales: allLocale(filter: { ns: { in: ["links", "subscribe"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    logo: file(relativePath: { eq: "images/jasgroup/jasgroup-logo.svg" }) {
      id
      publicURL
    }
  }
`

interface InvestorPageProps {
  data: {
    logo: PublicUrlType
  }
}

const InvestorPage = (props: InvestorPageProps): JSX.Element => {
  const { t, i18n } = useTranslation()
  const [content, setContent] = useState(contentEn)
  const { logo } = props.data

  useEffect(() => {
    switch (i18n.language) {
      case 'id':
        setContent(contentId)
        break
      case 'en':
        setContent(contentEn)
        break
      default:
        setContent(contentEn)
        break
    }
  }, [i18n.language])

  return (
    <>
      <SEO title={content.title} description={content.description} />
      {/* Start Hero Section */}
      <section className="uk-light">
        <div
          className="uk-section uk-background-norepeat uk-background-cover tm-jasgroup-hero-background uk-flex uk-flex-middle"
          uk-height-viewport=""
          uk-img=""
          style={{ minHeight: 'calc(100vh)' }}
        >
          <div className="uk-width-1-1">
            <div className="uk-container">
              <div className="uk-flex uk-flex-between" uk-grid="">
                <div className="uk-width-1-2@m uk-flex uk-flex-middle">
                  <div className="uk-animation-slide-top-medium uk-text-center uk-text-left@m">
                    <img className="uk-hidden@m" width="125" alt="" uk-img={logo.publicURL} />
                    <h1 className="uk-heading-small">{content.description}</h1>
                    <p className="uk-visible@m uk-text-lead">{content.description2}</p>
                  </div>
                </div>
                <div className="uk-width-1-2@m uk-flex uk-flex-right uk-visible@m">
                  <img width="" height="" alt="" uk-img={logo.publicURL} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Hero Section */}
      <Subscribe />
    </>
  )
}

export default InvestorPage
